
import { defineComponent } from "vue";

export default defineComponent({
  name: "total-premium-calculator-widget",
  props: {
    widgetClasses: String,
    background: String,
    amount: Number,
    title: String,
  },
  components: {},
});
