import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-body" }
const _hoisted_2 = { class: "d-flex text-center flex-column text-dark pt-8" }
const _hoisted_3 = { class: "fw-bolder fs-3" }
const _hoisted_4 = { class: "fw-bolder fs-2x pt-1" }
const _hoisted_5 = { id: "total-premium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card bgi-no-repeat bg-light-primary"]),
    style: _normalizeStyle([`background-image: url('/media/svg/shapes/${_ctx.background}')`, {"background-position":"right top","background-size":"30% auto"}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title ? _ctx.title : "Total Premium"), 1),
        _createElementVNode("span", _hoisted_4, [
          _createTextVNode("₦"),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.amount), 1)
        ])
      ])
    ])
  ], 6))
}